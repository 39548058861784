import { h } from "preact";
import { useState } from "preact/hooks";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Grid as MuiGrid, styled } from "@mui/material";
import Link from "@mui/material/Link";
import { Button } from "uninfo-components";
import CountryPicker from "components/common/CountryPicker";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import theme from "components/theme";

const Grid = styled(MuiGrid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: { display: "none" }
}));

function MenuForMobile() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const handleMenuClick = (event: { currentTarget: HTMLElement }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item>
      <Button
        aria-controls="main-dropdown-menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
      >
        <MenuIcon />
      </Button>
      <Menu
        id="main-dropdown-menu"
        anchorEl={anchorEl}
        keepMounted
        marginThreshold={0}
        PaperProps={{
          style: {
            width: "100%",
            maxWidth: "100%",
            padding: theme.spacing(1),
            left: 0,
            right: 0
          }
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Close</MenuItem>
        <MenuItem>
          <Link href="/">
            <Typography>{t("Home")}</Typography>
          </Link>
        </MenuItem>
        {/* <MenuItem>
          <Link href="/global">
            <Typography variant="body1">{t("Global")}</Typography>
          </Link>
        </MenuItem> */}
        <MenuItem>
          <Link href="/all-countries">
            <Typography variant="body1">{t("Countries")}</Typography>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link href="/data-explorer">
            <Typography variant="body1">{t("Data Explorer")}</Typography>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link href="/covid-19">
            <Typography variant="body1">{t("Covid 19")}</Typography>
          </Link>
        </MenuItem>
        <MenuItem>
          <CountryPicker redirect onChange={handleClose} fullWidth={true} />
        </MenuItem>
      </Menu>
    </Grid>
  );
}
export default MenuForMobile;
