import { h } from "preact";
import { useState, useContext } from "preact/hooks";
import { Suspense, lazy } from "preact/compat";

import { useTranslation } from "react-i18next";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

import MenuForMobile from "./MenuForMobile";
import SubMenu from "components/common/SubMenu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CircularProgress } from "uninfo-components";
import { styled } from "@mui/material/styles";
import LanguageMenuItem from "./LanguageMenuItem";

const UserMenu = lazy(() => import("./UserMenu"));

const MenuContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(2, 0)
}));

const MenuTypography = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(3),
  cursor: "pointer",
  color: theme.palette.text.primary
}));

const Links = styled(Grid)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("md")]: { display: "none" }
}));

const Header = (): h.JSX.Element => {
  const con = useTranslation();
  const { t, i18n } = con;
  document.body.dir = i18n.dir();
  const [anchorSubMenu, setAnchorSubMenu] = useState<HTMLElement | null>(null);

  const handleSubMenu = (event: { currentTarget: HTMLElement }) => {
    setAnchorSubMenu(event.currentTarget);
  };

  const handleCloseSubMenu = () => {
    setAnchorSubMenu(null);
  };

  return (
    <AppBar position="fixed" color="inherit" elevation={0}>
      <div
        style={{
          textAlign: "center",
          background: "red",
          color: "white",
          width: "100%",
          padding: ".5rem 0"
        }}
      >
        IMPORTANT: Please note that this site is in a beta phase. The data is
        currently being validated and verified.
      </div>
      {<TopBar />}
      <Toolbar>
        <MenuContainer>
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <MenuForMobile />
            <Link href="/" sx={{ display: "flex", alignItems: "center" }}>
              <img
                width={170}
                src="/assets/logos/logo_undg.png"
                alt={t("UN Development Goals")}
                style={{ marginRight: 3 }}
              />
              <img
                width={100}
                src="/assets/logos/uninfo-logo-small.png"
                alt={t("UN Info")}
                style={{ marginRight: 3 }}
              />
            </Link>
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Links item>
                <Link href="/">
                  <MenuTypography variant="body1">{t("Home")}</MenuTypography>
                </Link>
                {/* <Link href="/global">
                  <MenuTypography variant="body1">
                    {t("Global")}
                  </MenuTypography>
                </Link> */}
                <MenuTypography variant="body1" onClick={handleSubMenu}>
                  {t("Countries")}
                </MenuTypography>
                <Link href="/data-explorer">
                  <MenuTypography variant="body1">
                    {t("Data Explorer")}
                  </MenuTypography>
                </Link>
                <Link href="https://data.uninfo.org/">
                  <MenuTypography variant="body1">
                    {t("Covid 19")}
                  </MenuTypography>
                </Link>
                <Link href="/documents">
                  <MenuTypography variant="body1">
                    {t("Documents")}
                  </MenuTypography>
                </Link>
              </Links>
              <Grid>
                {typeof window !== "undefined" && (
                  <Suspense fallback={<CircularProgress />}>
                    <UserMenu />
                  </Suspense>
                )}
              </Grid>
            </Grid>
          </Grid>
        </MenuContainer>
      </Toolbar>
      {anchorSubMenu && (
        <SubMenu
          links={[{ title: "All countries", path: "/all-countries" }]}
          anchorEl={anchorSubMenu}
          handleClose={handleCloseSubMenu}
        />
      )}
    </AppBar>
  );
};

const TopBarContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  borderBottom: `2px solid ${theme.palette.primary.main}`
}));

const TopBar = (): h.JSX.Element => {
  const { t } = useTranslation();

  const [anchorPopper, setAnchorPopper] = useState<HTMLElement | null>(null);

  const handleOpenPopper = (event: { currentTarget: HTMLElement }) => {
    setAnchorPopper(event.currentTarget);
  };

  const handleClosePopper = () => {
    setAnchorPopper(null);
  };

  return (
    <TopBarContainer>
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1px 0 0"
        }}
      >
        <Grid item>
          <span
            onClick={handleOpenPopper}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Typography style={{ fontSize: "0.875rem" }}>
              {t("Key resources")}
            </Typography>
            <ExpandMoreIcon
              sx={{ transform: anchorPopper ? "rotate(180deg)" : "" }}
            />
          </span>
          <SubMenu
            sx={{
              "& .MuiPaper-root": { backgroundColor: "#e0e0e0" },
              "& .MuiPaper-root p": { fontSize: "0.95rem" }
            }}
            links={[
              {
                title: "SDG certification",
                path: "https://sdgcertification.un.org/"
              },
              {
                title: "SDG Primer",
                path: "https://sdgprimer.un.org/"
              },
              {
                title: "UNSDG website",
                path: "https://unsdg.un.org/"
              },
              {
                title: "COVID-19 data portal",
                path: "https://data.uninfo.org/"
              },
              {
                title: "UNINFO data entry",
                path: "https://workspace.uninfo.org/"
              },
              {
                title: "DCO website",
                path: "https://un-dco.org/"
              }
            ]}
            setCountryPicker={false}
            anchorEl={anchorPopper}
            handleClose={handleClosePopper}
          />
        </Grid>
        <Grid>
          <LanguageMenuItem
            sx={{
              "& .MuiPaper-root": { backgroundColor: "#e0e0e0" },
              "& .MuiPaper-root p": { fontSize: "0.95rem" }
            }}
          />
        </Grid>
      </Container>
    </TopBarContainer>
  );
};

export default Header;
