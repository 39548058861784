/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
const settings = {};

if (typeof window !== "undefined") {
  // eslint-disable-next-line no-console
  settings.API_URL = window?.ENV?.API_URL || "http://localhost:5100/";
  settings.API_VERSION = window?.ENV?.API_VERSION || "v0.1";
  settings.EXTERNAL_STORAGE =
    window?.ENV?.EXTERNAL_STORAGE ||
    "https://minio.dev.devqube.io/uninfo-staging-main/";
  settings.MVP_URL = window?.ENV?.MVP_URL || "http://localhost:3000";
  settings.PORTAL_URL = window?.ENV?.PORTAL_URL || "http://localhost:8080";
  settings.NODE_ENV = window?.ENV?.NODE_ENV;
  settings.API_MAPBOX =
    "https://api.mapbox.com/styles/v1/aftab84/cklsfquhs1fja17o8n0348xf8/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYWZ0YWI4NCIsImEiOiJjamtrdngyc2owYWhjM3Btc2VwbWIzc3BsIn0.5pTKwhoNucAt2l0nHTPpYg";
  settings.HOLDING_PAGE = window?.ENV?.HOLDING_PAGE ?? false;
}

export default settings;
