import { h } from "preact";
import { useTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { IconButton as MuiIconButton } from "@mui/material";
import Container from "@mui/material/Container";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { styled } from "@mui/styles";

const GridMainContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(1),
  marginTop: theme.spacing(2)
}));

const SubContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  flexGrow: 1
}));

const LinkContainer = styled(Container)(({ theme }) => ({
  borderBottom: "1px solid grey",
  padding: theme.spacing(2),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
}));

const Img = styled("img")(({ theme }) => ({
  marginRight: theme.spacing(3)
}));

const FooterLinkGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: "flex"
}));

const FooterLink = styled(Link)(({ theme }) => ({
  marginRight: theme.spacing(2),
  color: theme.palette.grey[800],
  "&:hover": {
    color: theme.palette.primary.light
  }
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  color: theme.palette.secondary.light,
  padding: 8,
  backgroundColor: theme.palette.grey[500],
  "& svg": {
    fontSize: "1.2rem"
  },
  "&:hover": {
    backgroundColor: theme.palette.grey[400]
  },
  marginRight: theme.spacing(1)
}));

const Footer = () => {
  const { t } = useTranslation();

  return (
    <GridMainContainer container justifyContent="center">
      <GridMainContainer container maxWidth={1400}>
        <LinkContainer fixed>
          <FooterLinkGrid item xs={12}>
            <FooterLink href="  https://unsdg.un.org/about/development-coordination-office">
              <Typography variant="subtitle2">{t("About")}</Typography>
            </FooterLink>
            <FooterLink href="https://uninfo.gitbook.io/wiki/data-portal/about-the-data-portal">
              <Typography variant="subtitle2">{t("Help")}</Typography>
            </FooterLink>
            {/*<Link className={classes.FooterLinks} href="#">
            <Typography variant="subtitle2">{t("Contact")}</Typography>
          </Link>*/}
            <FooterLink href="https://www.un.org/en/sections/about-website/privacy-notice/index.html">
              <Typography variant="subtitle2">{t("Privacy notice")}</Typography>
            </FooterLink>
            <FooterLink href="https://www.un.org/en/sections/about-website/terms-use/index.html">
              <Typography variant="subtitle2">{t("Terms of use")}</Typography>
            </FooterLink>
          </FooterLinkGrid>
          <Grid
            item
            xs={3}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Link href="https://www.linkedin.com/company/united-nations----un-development-group">
              <IconButton color="primary" size="large">
                <LinkedInIcon />
              </IconButton>
            </Link>
            <Link href="https://twitter.com/un_sdg">
              <IconButton color="primary" size="large">
                <TwitterIcon />
              </IconButton>
            </Link>
          </Grid>
        </LinkContainer>

        <SubContainer fixed>
          <Grid container spacing={2}>
            <Grid item xs>
              <Link
                href="https://un-dco.org"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Img
                  width={170}
                  src="/assets/logos/logo_undg_blue.png"
                  alt={t("UN Development Goals")}
                />
              </Link>
            </Grid>
            <Grid item sm={8} xs={12}>
              <Typography sx={{ fontSize: "0.7rem", marginBottom: 1 }}>
                This service is provided by UNDCO.
              </Typography>
              <Typography sx={{ fontSize: "0.7rem", marginBottom: 1 }}>
                The UN Development Coordination Office (UNDCO) is the
                secretariat of the UNSDG, bringing together the UN development
                system to promote change and innovation to deliver results
                together on sustainable development.
              </Typography>
              <Typography sx={{ fontSize: "0.7rem" }}>
                For feedback and queries on this site, please contact{" "}
                <Link href="mailto:uninfo@un.org">uninfo@un.org</Link>
              </Typography>
            </Grid>
            <Grid item sx={{ textAlign: "end" }} xs>
              <div>
                <img
                  alt="Licence Creative Commons"
                  width={20}
                  style={{ borderWidth: 0, marginRight: 0 }}
                  src="/assets/icons/cc.png"
                />
                <img
                  alt="Licence Creative Commons"
                  width={20}
                  style={{ borderWidth: 0 }}
                  src="/assets/icons/human.png"
                />
              </div>
              <Typography sx={{ fontSize: "0.7rem" }}>
                Except where otherwise noted, content on this site is licensed
                under a{" "}
                <Link href="http://creativecommons.org/licenses/by/4.0/">
                  Creative Commons Attribution 4.0
                </Link>{" "}
                International license
              </Typography>
              <Typography sx={{ fontSize: "0.7rem" }}>
                Maps powered by{" "}
                <Link
                  target="_blank"
                  href="https://github.com/Leaflet/Leaflet/blob/main/LICENSE#L17"
                >
                  Leaflet
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </SubContainer>
      </GridMainContainer>
    </GridMainContainer>
  );
};

export default Footer;
