import { h, Fragment } from "preact";
import { Menu, MenuItem, styled, Typography } from "@mui/material";
import { useCallback, useContext, useState } from "preact/hooks";
import { GlobalContext } from "globalState";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface ILanguage {
  short: string;
  name: string;
  hideToggleForUsers?: boolean;
}
const defaultLanguages: ILanguage[] = [
  {
    short: "vi",
    name: "Tiếng Việt",
    hideToggleForUsers: true
  },
  {
    short: "fr",
    name: "Français",
    hideToggleForUsers: true
  },
  {
    short: "en",
    name: "English"
  },
  {
    short: "ar",
    name: "العربية",
    hideToggleForUsers: true
  },
  {
    short: "es",
    name: "Español",
    hideToggleForUsers: true
  },
  {
    short: "lo",
    name: "Laos",
    hideToggleForUsers: true
  },
  {
    short: "pt",
    name: "Português",
    hideToggleForUsers: true
  },
  {
    short: "ru",
    name: "Русский",
    hideToggleForUsers: true
  },
  {
    short: "tk",
    name: "Türkmenler",
    hideToggleForUsers: true
  },
  {
    short: "zh",
    name: "中国人",
    hideToggleForUsers: true
  },
  {
    short: "sq",
    name: "Shqip",
    hideToggleForUsers: true
  },
  {
    short: "am",
    name: "հայերեն",
    hideToggleForUsers: true
  },
  {
    short: "be",
    name: "Беларуская",
    hideToggleForUsers: true
  },
  {
    short: "hbs",
    name: "BHS",
    hideToggleForUsers: true
  },
  {
    short: "tg",
    name: "Тоҷикӣ",
    hideToggleForUsers: true
  },
  {
    short: "sw",
    name: "Kiswahili",
    hideToggleForUsers: true
  },
  {
    short: "tr",
    name: "Türkçe",
    hideToggleForUsers: true
  },
  {
    short: "ur",
    name: "Urdu",
    hideToggleForUsers: true
  }
];

interface Props {
  overridingClass?: string;
  languages?: ILanguage[];
  sx?: any;
  label?: string;
}

const StyledSubMenu = styled(Menu)(({ theme }) => ({
  "& 	.MuiMenu-paper": {
    borderRadius: 0,
    borderTop: `2px solid ${theme.palette.primary.main}`
  }
}));

const MenuItemLabel = ({
  label,
  onClick,
  anchorPopper,
  canOpen
}: {
  canOpen: boolean;
  label: string;
  onClick: (event: { currentTarget: HTMLElement }) => void;
  anchorPopper: any;
}): h.JSX.Element => {
  return (
    <span
      onClick={canOpen ? onClick : () => null}
      style={{ display: "flex", alignItems: "center" }}
    >
      <Typography style={{ fontSize: "0.875rem" }}>{label}</Typography>
      {canOpen && (
        <ExpandMoreIcon
          data-testid="lng-menu-arrow-icon"
          sx={{ transform: anchorPopper ? "rotate(180deg)" : "" }}
        />
      )}
    </span>
  );
};

const LanguageMenuItem = ({
  label,
  languages = defaultLanguages,
  overridingClass,
  sx
}: Props) => {
  const [anchorPopper, setAnchorPopper] = useState<HTMLElement | null>(null);

  const { t } = useTranslation();
  const { state } = useContext(GlobalContext);

  const setLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    document.body.dir = i18n.dir();
  };

  const handleOpenPopper = (event: { currentTarget: HTMLElement }) => {
    setAnchorPopper(event.currentTarget);
  };

  const handleClosePopper = (item: any): void => {
    setAnchorPopper(null);
    setLanguage(item.short);
    // handleClose(item);
  };

  const isCFEditorUser = !!state.user?.domains?.filter(
    d => d.short === "CFR" && d.userDomain?.legacyRoleId === 2
  ).length;

  const languagesToDisplay =
    state.user?.isAdmin || isCFEditorUser
      ? languages
      : languages.filter(l => !l.hideToggleForUsers);

  const renderMenuItems = useCallback((items: ILanguage[]) => {
    return items?.map(item => (
      <MenuItem
        selected={item.short === i18n.language}
        onClick={() => handleClosePopper(item)}
        key={item?.name}
      >
        <Typography
          variant="body1"
          sx={{
            marginRight: theme => theme.spacing(2),
            cursor: "pointer",
            color: theme => theme.palette.text.primary
          }}
        >
          {t(item.name)}
        </Typography>
      </MenuItem>
    ));
  }, []);

  return (
    <>
      <MenuItemLabel
        canOpen={languagesToDisplay.length > 1}
        label={
          label ??
          `Language: ${languagesToDisplay.find(l => l.short === i18n.language)
            ?.name ?? i18n.language}`
        }
        onClick={handleOpenPopper}
        anchorPopper={anchorPopper}
      />
      <StyledSubMenu
        anchorEl={anchorPopper}
        open={Boolean(anchorPopper)}
        onClose={handleClosePopper}
        className={overridingClass}
        elevation={1}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        disableAutoFocusItem={true}
        sx={sx ?? {}}
      >
        {renderMenuItems(languagesToDisplay)}
      </StyledSubMenu>
    </>
  );
};

export default LanguageMenuItem;
