import { createTheme, darken } from "@mui/material/styles";

let theme = createTheme({
  typography: {
    h1: {
      fontSize: "2.7rem",
      fontWeight: "bold"
    },
    h2: {
      fontSize: "1.7rem",
      fontWeight: "bold"
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: "bold"
    },
    h4: {
      fontSize: "1.35rem"
    },
    body2: {
      fontFamily: [
        "Roboto",
        "Noto",
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(",")
    },
    fontFamily: [
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    subtitle1: {
      fontWeight: "bold"
    },
    caption: {
      fontFamily: '"Oswald", sans-serif'
    }
  },

  palette: {
    tonalOffset: 0.2,
    primary: {
      main: "#009EDB"
    },
    secondary: {
      light: "#fff",
      main: "#f2f2f2"
    },
    warning: {
      main: "#173d57"
    },
    text: {
      disabled: "rgba(0, 0, 0, .2)"
    },
    orange: {
      main: "#F58220",
      light: "#f8a762",
      dark: "#c46819"
    },
    sdgs: {
      // Note material-ui.d.ts for how this is typed
      "1": {
        main: "#EE3124"
      },
      "2": {
        main: "#DBA63C"
      },
      "3": {
        main: "#0DB14B"
      },
      "4": {
        main: "#D22333"
      },
      "5": {
        main: "#F04E37"
      },
      "6": {
        main: "#44C8F5"
      },
      "7": {
        main: "#FFC20E"
      },
      "8": {
        main: "#A71D44"
      },
      "9": {
        main: "#F58220"
      },
      "10": {
        main: "#EE4095"
      },
      "11": {
        main: "#FAA634"
      },
      "12": {
        main: "#BB892C"
      },
      "13": {
        main: "#387B46"
      },
      "14": {
        main: "#00AEEF"
      },
      "15": {
        main: "#8DC63F"
      },
      "16": {
        main: "#006CB7"
      },
      "17": {
        main: "#184B6E"
      }
    }
  }
});

// We might need to turn all of these into our own components and use
// them directly for the sake of the widgets
theme = createTheme(theme, {
  components: {
    MuiLink: {
      defaultProps: {
        underline: "none"
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          textTransform: "none",
          fontSize: theme.typography.subtitle1.fontSize
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${theme.palette.grey[300]}`
        },
        flexContainer: {
          justifyContent: "space-between"
        },
        indicator: {
          display: "flex",
          justifyContent: "center",
          height: 5,
          backgroundColor: "transparent",
          // color: theme.palette.warning.main
          "&::before": {
            display: "inline-block",
            content: '""',
            maxWidth: 50,
            borderTopRightRadius: "1rem",
            borderTopLeftRadius: "1rem",
            width: "100%",
            backgroundColor: theme.palette.primary.main
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: "100%"
        },
        input: {
          marginLeft: theme.spacing(1)
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "2rem"
        },
        input: {
          // "&:not(.MuiSelect-root)": {
          padding: "0 32px 0 0 !important",
          minHeight: "30px"
          // }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[200],
          color: theme.palette.primary.main,
          "& p": {
            fontWeight: "bold"
          },
          "& svg": {
            fill: theme.palette.secondary.light
          },
          "&.Mui-focused": {
            backgroundColor: theme.palette.primary.main
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          display: "block"
        }
      }
    }
  }
});

export default theme;
