import { FunctionalComponent, h, Fragment } from "preact";
import { useReducer, useEffect, useCallback, useState } from "preact/hooks";
import CssBaseline from "@mui/material/CssBaseline";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider
} from "@mui/material/styles";
import { Helmet } from "react-helmet";
import jwtDecode, { JwtPayload } from "jwt-decode";
import Main from "./Main";
import {
  getRegions,
  getWorkspaces,
  getFileDocumentTypes,
  getTimeframes
} from "services/Api";

import "../i18n/config";

import { initialState, GlobalContext, reducer } from "globalState";

import theme from "./theme";
import config from "config";
import HoldingPage from "components/HoldingPage";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";

const header = document.querySelector("head");
const emotionCache = createCache({
  key: "css",
  container: header ?? undefined, // pointing to <iframe>.document in this case, ymmv
  prepend: true, // ymmv
  speedy: false // <--- key setting
});

const App: FunctionalComponent = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [isLoaded, setIsLoaded] = useState(false);
  const token = localStorage.getItem("token");
  const getWorkspacesCallback = useCallback(async () => {
    try {
      const countryResponse = await getWorkspaces({
        exclude: ["contacts", "users", "regions"]
      });
      // delete DCO and global workspace waiting for this suppression back-end side
      const countriesSorted = countryResponse.results
        .filter(
          r =>
            r.id !== 249 &&
            r.id !== 242 &&
            r.id !== 245 &&
            r.id !== 246 &&
            r.id !== 244 &&
            r.id !== 243
        )
        .sort((a, b) => a.name.localeCompare(b.name));
      dispatch({ type: "setWorkspaces", workspaces: countriesSorted });
    } catch (e) {
      console.error(e);
      return [];
    }
  }, []);

  const getRegionsCallback = useCallback(async () => {
    try {
      const regionsResponse = await getRegions();
      const regionsSorted = regionsResponse.results.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      dispatch({
        type: "setRegions",
        regions: regionsSorted
      });
    } catch (e) {
      console.error(e);
      return [];
    }
  }, []);

  const getDocumentTypesCallback = useCallback(async () => {
    try {
      const typesResponse = await getFileDocumentTypes();
      const typesSorted = typesResponse.results.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      dispatch({
        type: "setDocumentTypes",
        documentTypes: typesSorted
      });
    } catch (e) {
      console.error(e);
      return [];
    }
  }, []);

  const getYearsCallback = useCallback(async () => {
    try {
      const yearlyResponse = await getTimeframes({ type: "yearly" });
      const fiscalResponse = await getTimeframes({ type: "fiscalYear" });
      const years = [...yearlyResponse.results, ...fiscalResponse.results];
      const yearsSorted = years
        .map(r => {
          const year = new Date(r.startDate).getUTCFullYear();
          return { ...r, name: year };
        })
        .sort((a, b) => a.name - b.name);
      dispatch({
        type: "setYears",
        years: yearsSorted
      });
    } catch (e) {
      console.error(e);
      return [];
    }
  }, []);

  useEffect(() => {
    getWorkspacesCallback();
    getRegionsCallback();
    getDocumentTypesCallback();
    getYearsCallback();
  }, [
    getWorkspacesCallback,
    getRegionsCallback,
    getDocumentTypesCallback,
    getYearsCallback
  ]);

  useEffect(() => {
    dispatch({ type: "setYear", year: `${new Date().getFullYear()}` });
  }, [state.year]);

  if (token) {
    const decoded = jwtDecode<JwtPayload>(token);
    const exp = decoded.exp;
    const current_time = Date.now() / 1000;
    if (exp && exp < current_time) {
      //expired token
      localStorage.removeItem("token");
      dispatch({ type: "setUser", user: undefined });
    }
  }

  if (state && state?.workspaces && state?.workspaces?.length > 0) {
    setIsLoaded(true);
  }
  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <GlobalContext.Provider value={{ state, dispatch }}>
          <CssBaseline />
          <Helmet titleTemplate="%s | UNSDG Data Portal">
            <title>Home</title>
          </Helmet>
          {config.HOLDING_PAGE && <HoldingPage />}
          {!config.HOLDING_PAGE && <Main isLoaded={isLoaded} />}
        </GlobalContext.Provider>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default App;
