import { FunctionComponent, h } from "preact";
import {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef
} from "preact/hooks";
import { route } from "preact-router";
import { useTranslation } from "react-i18next";

import Autocomplete, {
  AutocompleteRenderInputParams
} from "@mui/material/Autocomplete";
import { TextField } from "uninfo-components";

import { getWorkspaces } from "services/Api";
import { GlobalContext } from "globalState";

interface CountryPickerProps {
  onChange?: (value: Workspace | Workspace[] | null) => void;
  fullWidth?: boolean;
  redirect?: boolean;
  variant?: "filled" | "outlined" | "standard";
  onCloseSubMenu?: () => void;
  noCache?: boolean;
  multiple?: boolean;
  searchConstraints?: { workspaceIds: number[] };
  style?: any | null;
  autoFocus?: boolean;
  label?: string | "Country";
}

const CountryPicker: FunctionComponent<CountryPickerProps> = ({
  onChange,
  redirect,
  fullWidth = false,
  variant = "outlined",
  onCloseSubMenu,
  noCache = false,
  autoFocus = false,
  multiple = false,
  style,
  searchConstraints,
  label
}: CountryPickerProps) => {
  const [options, setOptions] = useState<Workspace[] | undefined>([]);
  const { t } = useTranslation();
  const { state } = useContext(GlobalContext);
  const { workspaces } = state;
  const inputRef = useRef<HTMLInputElement>();

  const getWorkspacesCallback = useCallback(
    async (val?: string) => {
      try {
        const countryResponse = await getWorkspaces({
          q: val,
          ...(searchConstraints ? searchConstraints : {})
        });
        const responses = countryResponse.results;

        setOptions(responses);
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    [searchConstraints]
  );

  useEffect(() => {
    if (!noCache) {
      setOptions(workspaces);
    }

    if (searchConstraints && searchConstraints.workspaceIds.length > 0) {
      setOptions(
        workspaces?.filter(
          w => searchConstraints.workspaceIds.indexOf(w.id) !== -1
        )
      );
    }
  }, [workspaces, noCache, searchConstraints]);

  return (
    <Autocomplete
      id="country-search"
      fullWidth={fullWidth}
      style={style ?? null}
      multiple={multiple}
      options={options || []}
      onInputChange={(_, newInputValue) => {
        if (noCache) {
          getWorkspacesCallback(newInputValue);
        }
      }}
      autoHighlight
      sx={{
        marginRight: 2,
        color: "palette.text.primary",
        minWidth: 200
      }}
      getOptionLabel={(value: Workspace) => value.name ?? ""}
      renderInput={params => (
        <TextFieldWrapper
          params={params}
          variant={variant}
          autoFocus={autoFocus}
          label={label}
        />
      )}
      onChange={(event, value) => {
        if (value) {
          if (redirect && !Array.isArray(value)) {
            route(`/location/${value.id}`);
            onCloseSubMenu && onCloseSubMenu();
          }
          if (onChange) {
            onChange(value);
          }
        } else if (onChange) {
          onChange(value);
        }
      }}
    />
  );
};

const TextFieldWrapper = ({
  params,
  variant,
  autoFocus,
  label
}: {
  params: AutocompleteRenderInputParams;
  variant: "filled" | "outlined" | "standard";
  autoFocus: boolean;
  label?: string;
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (autoFocus) {
        inputRef?.current?.focus();
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [autoFocus]);

  return (
    <TextField
      {...params}
      label={t(label ?? "Country")}
      variant={variant}
      inputRef={inputRef}
      size="small"
    />
  );
};

export default CountryPicker;
