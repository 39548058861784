import i18n from "i18next";
import enTranslation from "./en/translation.json";
import frTranslation from "./fr/translation.json";
import viTranslation from "./vi/translation.json";
import arTranslation from "./ar/translation.json";
import esTranslation from "./es/translation.json";
import loTranslation from "./lo/translation.json";
import ptTranslation from "./pt/translation.json";
import ruTranslation from "./ru/translation.json";
import tkTranslation from "./tk/translation.json";
import zhTranslation from "./zh/translation.json";
import sqTranslation from "./sq/translation.json";
import amTranslation from "./am/translation.json";
import beTranslation from "./be/translation.json";
import tgTranslation from "./tg/translation.json";
import swTranslation from "./sw/translation.json";
import hbsTranslation from "./hbs/translation.json";
import trTranslation from "./tr/translation.json";
import urTranslation from "./ur/translation.json";

import { initReactI18next } from "react-i18next";

export const resources = {
  en: {
    translation: enTranslation
  },
  fr: {
    translation: frTranslation
  },
  vi: {
    translation: viTranslation
  },
  ar: {
    translation: arTranslation
  },
  es: {
    translation: esTranslation
  },
  lo: {
    translation: loTranslation
  },
  pt: {
    translation: ptTranslation
  },
  ru: {
    translation: ruTranslation
  },
  tk: {
    translation: tkTranslation
  },
  zh: {
    translation: zhTranslation
  },
  sq: {
    translation: sqTranslation
  },
  am: {
    translation: amTranslation
  },
  be: {
    translation: beTranslation
  },
  tg: {
    translation: tgTranslation
  },
  sw: {
    translation: swTranslation
  },
  hbs: {
    translation: hbsTranslation
  },
  tr: {
    translation: trTranslation
  },
  ur: {
    translation: urTranslation
  }
} as const;

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  resources,
  interpolation: { escapeValue: false }
});

export default { i18n };
