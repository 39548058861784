import { FunctionalComponent, h, Fragment } from "preact";
import { Route, Router } from "preact-router";
import Container from "@mui/material/Container";

import Home from "routes/Home";
import Country from "routes/Country";
import Global from "routes/Global";
import Region from "routes/Region";
import DataExplorer from "routes/DataExplorer";
import Countries from "routes/Countries";
import Documents from "routes/Documents";
import "../i18n/config";

import NotFoundPage from "routes/NotFound";
import Header from "./header";
import Footer from "./footer";

import { CircularProgress } from "uninfo-components";
import { styled } from "@mui/material";

interface Props {
  isLoaded: boolean;
}

const Wrapper = styled("div")(() => ({
  height: "100%",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  background: "white"
}));

const SubWrapper = styled("div")(({ theme }) => ({
  marginBottom: "100px",
  flexGrow: 1,
  [theme.breakpoints.down("lg")]: {
    marginBottom: "50px"
  },
  background: "white",
  maxWidth: 1400,
  margin: "130px auto"
}));

const MainContainer = styled(Container)(() => ({
  "& .MuiContainer-maxWidthXl": { maxWidth: 1400 }
}));

const Main = ({ isLoaded }: Props) => {
  return (
    <Wrapper id="app">
      <Header />
      {isLoaded ? (
        <SubWrapper>
          <MainContainer maxWidth="xl">
            <Router>
              <Home path="/" />
              <Route path="/global" component={Global} />
              <Route path="/documents" component={Documents} />
              <Route path="/data-explorer/:rest*" component={DataExplorer} />
              <Route path="/all-countries" component={Countries} />
              <Route path="/location/:id/:rest*" component={Country} />
              <Route path="/region/:id" component={Region} />
              <NotFoundPage default />
            </Router>
          </MainContainer>
        </SubWrapper>
      ) : (
        <SubWrapper>
          <CircularProgress />
        </SubWrapper>
      )}

      <Footer />
    </Wrapper>
  );
};

export default Main;
