import settings from "config";
import { flatten } from "lodash";
import qs from "query-string";

import {
  STRATEGICPRIORITY_COREENTITYTYPE_ID,
  CFR_DOMAIN_ID,
  INDICATOR_COREENTITYTYPE_ID,
  SUBOUTPUT_COREENTITYTYPE_ID,
  OUTCOME_COREENTITYTYPE_ID,
  JWT_COREENTITYTYPE_ID,
  CFR_PLANTYPE_ID,
  UNDAF_PLANTYPE_ID
} from "dataConstants";

export const BASE_URL = `${settings.API_URL as string}${settings.API_VERSION as string}`;

interface APIResults<T> {
  results: T[];
  pagination?: {
    currentPage: number;
    limit: number;
    pages: number;
    total: number;
  };
}

const postParams = (body = {}, headers = {}) => ({
  method: "POST",
  headers: {
    ...headers,
    "Content-Type": "application/json"
  },
  body: JSON.stringify(body)
});

class AuthError extends Error {
  constructor(params: any) {
    super(...params);
    Object.setPrototypeOf(this, AuthError.prototype);
    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthError);
    }

    this.message = "Unauthorized";
  }
}

const unwrap = <T>(response: { json: () => T; status: number }) => {
  if (response.status === 401) {
    throw new AuthError(response);
  }
  return response.json();
};
export const getBlueprints = async (
  options: {
    q?: string;
    exclude?: string[];
    blueprintIds?: number[];
  } = {}
): Promise<APIResults<Blueprint>> => {
  return fetch(
    `${BASE_URL}/blueprint/search`,
    postParams({ ...options, sort: [["name", "ASC"]], limit: 50 })
  ).then(unwrap) as Promise<APIResults<Blueprint>>;
};

export const getBlueprintById = (
  blueprintId: number | string
): Promise<Blueprint> => {
  return fetch(`${BASE_URL}/blueprint/${blueprintId}`, {
    method: "GET"
  }).then(unwrap);
};

export const getWorkspaces = async (
  options: {
    q?: string;
    exclude?: string[];
  } = {}
): Promise<APIResults<Workspace>> => {
  return fetch(
    `${BASE_URL}/workspace/search`,
    postParams({ ...options, sort: [["name", "ASC"]], limit: 1000 })
  ).then(unwrap) as Promise<APIResults<Workspace>>;
};

export const getOrganizations = async (
  options: {
    unsdg?: boolean;
    q?: string;
    limit?: number;
    workspaceIds?: number[];
  } = {}
): Promise<APIResults<Organization>> => {
  return fetch(
    `${BASE_URL}/organization/search`,
    postParams({ ...options, sort: [["id", "ASC"]] })
  ).then(unwrap) as Promise<APIResults<Organization>>;
};

export const getWorkspace = async (id: number): Promise<Workspace> => {
  return fetch(`${BASE_URL}/workspace/${id}`, {
    method: "GET"
  }).then(unwrap) as Promise<Workspace>;
};

export const getPlans = async (options: {
  workspaceIds?: number[];
  domainIds?: number[];
  planTypeIds?: number[];
  startDate?: string;
  endDate?: string;
  q?: string;
  limit?: number;
  sort?: SortAPI[];
  latestTaggedVersion?: boolean;
}): Promise<APIResults<Plan>> => {
  return fetch(`${BASE_URL}/plan/public/search`, postParams(options)).then(
    unwrap
  ) as Promise<APIResults<Plan>>;
};

// Need to add this new endpoint waiting for the backend
// to find a better solution to handle planType
export const getPlansWithPlanType = async (options: {
  workspaceIds?: number[];
  domainIds?: number[];
  planTypeIds?: number[];
  startDate?: string;
  endDate?: string;
  q?: string;
  limit?: number;
  sort?: SortAPI[];
  latestTaggedVersion?: boolean;
}): Promise<Plan[]> => {
  const planResponse = await getPlans(options);
  const blueprintIds = planResponse?.results?.map(pr => pr.blueprintId);
  const blueprints = await getBlueprints({ blueprintIds });
  return planResponse?.results?.map(plan => {
    const blueprint = blueprints?.results?.find(
      bp => bp.id === plan.blueprintId
    );
    if (blueprint) {
      return {
        ...plan,
        planType: blueprint.planType
      };
    }
    return plan;
  });
};

export const getPlanEntities = async (options: {
  includeMetrics?: boolean;
  includeChildren?: boolean;
  includeFieldData?: boolean;
  includeDataObject?: boolean;
  entityPrototypeIds?: number[];
  sort?: SortAPI[];
  where?: { [key: string]: any };
}): Promise<APIResults<PlanEntity>> => {
  return fetch(
    `${BASE_URL}/plan/public/entity/search`,
    postParams({
      ...options,
      sort: options.sort ?? [
        ["planEntityVersion.code", "ASC"],
        ["planEntityVersion.name", "ASC"]
      ],
      limit: 2500
    })
  ).then(unwrap) as Promise<APIResults<PlanEntity>>;
};

export interface GetContactsOptions extends APIQueryOptions {
  workspaceId?: number | number[];
  group?: GroupType | GroupType[];
  inCountryPresence?: boolean;
  agencyId?: number;
  jobTitleIds?: number[];
  limit?: number;
  sort?: SortAPI[];
  q?: string;
}

export const getContacts = async (
  options?: GetContactsOptions
): Promise<APIResults<Contact>> => {
  return fetch(
    `${BASE_URL}/contact/search`,
    postParams({
      ...(options ? options : {}),
      limit: options?.limit ? options.limit : 50,
      sort: options?.sort ? options.sort : [["agency.name", "ASC"]]
    })
  ).then(unwrap) as Promise<APIResults<Contact>>;
};

export const getCountry = async (id: number): Promise<Country> => {
  return fetch(`${BASE_URL}/country/${id}`, {
    method: "GET"
  }).then(unwrap) as Promise<Country>;
};

export const getStatuses = async (): Promise<APIResults<DataItem>> => {
  return fetch(
    `${BASE_URL}/dataitem/search`,
    postParams({ datasourceIds: [7] })
  ).then(unwrap) as Promise<APIResults<DataItem>>;
};

export const getRegions = async (): Promise<APIResults<Region>> => {
  return fetch(`${BASE_URL}/region/search`, postParams()).then(
    unwrap
  ) as Promise<APIResults<Region>>;
};

export const getRegion = async (id: number): Promise<Region> => {
  return fetch(`${BASE_URL}/region/${id}`, {
    method: "GET"
  }).then(response => response.json()) as Promise<Region>;
};

export const getTimeframes = async (options: {
  type: "yearly" | "quarterly" | "custom" | "fiscalYear";
  startDate?: string;
  endDate?: string;
  dateRange?: boolean;
}): Promise<APIResults<Timeframe>> => {
  return fetch(
    `${BASE_URL}/timeframe/search`,
    postParams({ ...options, limit: 100 })
  ).then(unwrap) as Promise<APIResults<Timeframe>>;
};

export const getIndicatorEntityReport = async (planOptions: {
  workspaceId: number;
  planId: number;
  sort?: SortAPI[];
}): Promise<APIResults<PlanEntity>> => {
  return fetch(
    `${BASE_URL}/report/public/planEntity/${INDICATOR_COREENTITYTYPE_ID}`,
    postParams({ ...planOptions, limit: 1000 })
  ).then(unwrap) as Promise<APIResults<PlanEntity>>;
};

export const getOutcomes = async (planOptions: {
  workspaceIds: number[];
}): Promise<PlanEntity[]> => {
  const { results: plans } = await getPlans({
    ...planOptions,
    planTypeIds: [CFR_PLANTYPE_ID, UNDAF_PLANTYPE_ID],
    sort: [["planVersion.startDate", "DESC"]],
    latestTaggedVersion: true
  });

  const ePs = flatten(plans.map(p => p.entityPrototypes));
  const outcomes = ePs.filter(
    e => e.coreentityTypeId === OUTCOME_COREENTITYTYPE_ID
  );

  const entities = await getPlanEntities({
    includeChildren: true,
    entityPrototypeIds: outcomes.map(o => o.id)
  });

  return entities.results;
};
export const getJointWorkPlan = async (planOptions: {
  workspaceIds: number[];
}): Promise<PlanEntity[]> => {
  const { results: plans } = await getPlans({
    ...planOptions,
    planTypeIds: [CFR_PLANTYPE_ID, UNDAF_PLANTYPE_ID],
    sort: [["planVersion.startDate", "DESC"]],
    latestTaggedVersion: true
  });

  const ePs = flatten(plans.map(p => p.entityPrototypes));
  const jwt = ePs.filter(e => e.coreentityTypeId === JWT_COREENTITYTYPE_ID);

  const entities = await getPlanEntities({
    includeChildren: true,
    entityPrototypeIds: jwt.map(o => o.id)
  });

  return entities.results;
};
export const getSubOutputEntityReport = async (planOptions: {
  workspaceId: number;
  planId?: number;
  sort?: SortAPI[];
}): Promise<APIResults<PlanEntity>> => {
  return fetch(
    `${BASE_URL}/report/public/planEntity/${SUBOUTPUT_COREENTITYTYPE_ID}`,
    postParams({ ...planOptions, limit: 1000 })
  ).then(unwrap) as Promise<APIResults<PlanEntity>>;
};

export const getSubOutputs = async ({
  sort,
  includeMetrics,
  workspaceIds,
  planEntityOptions
}: {
  workspaceIds: number[];
  sort?: SortAPI[];
  includeMetrics?: boolean;
  planEntityOptions?: { [key: string]: number[] | any }[];
}): Promise<PlanEntity[]> => {
  const { results: plans } = await getPlans({
    workspaceIds,
    planTypeIds: [CFR_PLANTYPE_ID, UNDAF_PLANTYPE_ID],
    sort: [["planVersion.startDate", "DESC"]],
    limit: 1
  });

  const ePs = flatten(plans.map(p => p.entityPrototypes));
  const subOutputs = ePs.filter(
    e => e.coreentityTypeId === SUBOUTPUT_COREENTITYTYPE_ID
  );

  let where = undefined;

  if (planEntityOptions && planEntityOptions.length > 0) {
    where = {
      $and: [...planEntityOptions]
    };
  }

  const entities = await getPlanEntities({
    includeChildren: true,
    includeMetrics,
    sort,
    entityPrototypeIds: subOutputs.map(o => o.id),
    where
  });

  return entities.results;
};

export const getStrategicPriorities = async (planOptions: {
  workspaceIds: number[];
}): Promise<PlanEntity[]> => {
  const { results: plans } = await getPlans({
    ...planOptions,
    planTypeIds: [CFR_PLANTYPE_ID, UNDAF_PLANTYPE_ID],
    sort: [["planVersion.startDate", "DESC"]],
    latestTaggedVersion: true,
    limit: 1
  });

  const ePs = flatten(plans.map(p => p.entityPrototypes));
  const strategicPriorities = ePs.filter(
    e => e.coreentityTypeId === STRATEGICPRIORITY_COREENTITYTYPE_ID
  );

  const entities = await getPlanEntities({
    includeChildren: true,
    entityPrototypeIds: strategicPriorities.map(o => o.id)
  });

  return entities.results;
};

export const getSDGs = async (): Promise<APIResults<
  SustainableDevelopmentGoal
>> => {
  return fetch(
    `${BASE_URL}/sustainableDevelopmentGoal/search`,
    postParams()
  ).then(unwrap) as Promise<APIResults<SustainableDevelopmentGoal>>;
};

export const getLocations = async (options: {
  countryIds: number[];
  adminLevels?: number[];
  q?: string;
  exclude?: string[];
}): Promise<APIResults<Location>> => {
  return fetch(
    `${BASE_URL}/location/search`,
    postParams({ ...options, limit: 1500 })
  ).then(unwrap) as Promise<APIResults<Location>>;
};

export const getUserProfile = async (): Promise<User | null> => {
  const token = localStorage?.getItem("token") as string;
  if (token) {
    return (fetch(`${BASE_URL}/user/profile`, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(unwrap) as Promise<User>).catch(e => {
      console.error("e", e);
      if (e instanceof AuthError && e.message) {
        localStorage?.removeItem("token");
      }
      return null;
    });
  }
  return null;
};

export type SdgOptions =
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "11"
  | "12"
  | "13"
  | "14"
  | "15"
  | "16"
  | "17";

export const sdgReportUrl = `${BASE_URL}/sustainableDevelopmentGoal/overviewReport?`;

export const getForGraph = async (options?: {
  workspaceIds?: number[];
  timeframeIds?: number[];
  year?: number;
  grouping?: string;
  exclude?: string[];
  locationIds?: number[];
}): Promise<SDGMock[]> => {
  const query = qs.stringify(options ?? {});
  return fetch(`${sdgReportUrl}${query}`, {
    method: "GET"
  }).then(unwrap) as Promise<SDGMock[]>;
};

export const getNumberOfJwpPublished = async (options?: {
  workspaceIds?: number[];
}): Promise<{ planCount: number }> => {
  const query = qs.stringify(options ?? {});
  return fetch(`${BASE_URL}/sustainableDevelopmentGoal/countReport?${query}`, {
    method: "GET"
  }).then(unwrap) as Promise<{ planCount: number }>;
};

export interface SankeyResult {
  nodes: {
    type: "contributingPartner" | "agency" | "sdg";
    entities: {
      id: number;
      name: string;
      metrics: {
        total: number;
      }[];
      short: string;
      properties: {
        color: {
          hex: string;
        };
      };
    }[];
  }[];
  links: {
    links: {
      toId: number;
      value: number;
      fromId: number;
    }[];
    total: number;
    metricId: number;
    metricName: string;
  }[];
}

export const disaggregationReportUrl = `${BASE_URL}/sustainableDevelopmentGoal/disaggregationReport?`;

export type SankeyTo = "sdg" | "organization" | "location";

export type ToType =
  | "contributingPartner"
  | "agency"
  | "sdg"
  | "implementingPartner"
  | "location";

export const getForSankey = async (options?: {
  workspaceIds: number[];
  timeframeIds?: number[];
  metricIds?: 6[];
  from: SankeyTo;
  fromType?: ToType;
  to: SankeyTo;
  toType?: ToType;
}): Promise<SankeyResult> => {
  const query = qs.stringify(options ?? {});
  return fetch(`${disaggregationReportUrl}${query}`, {
    method: "GET"
  }).then(unwrap) as Promise<SankeyResult>;
};

interface FormSubmissionResults<T> extends APIResults<T> {
  fieldMap: {
    [key: string]: {
      id: number;
      key: string;
      label: string;
      multiple: boolean;
      type: string;
      fields?: any;
    };
  };
}

export const getFormById = async (options: {
  formIds?: number;
  planIds?: number[];
  onlyPrivateTo?: boolean;
}): Promise<Form> => {
  return fetch(`${BASE_URL}/form/search`, postParams({ ...options, limit: 1 }))
    .then(unwrap)
    .then(results => {
      return results.results[0];
    }) as Promise<Form>;
};

interface SubmissionWithFields extends Submission {
  [key: string]: any;
  id: number;
  plan: Plan;
  planEntity: PlanEntity;
  planId: number;
  planSubmission: Submission;
}

export const mapFormResults = <T>(
  data: FormSubmissionResults<SubmissionWithFields>
): FieldMapWithValue<any>[][] => {
  const results = data.results;
  const fieldMap = data.fieldMap;

  const resultsMapped = (result: any) =>
    Object.keys(result).reduce((accum, k) => {
      if (result[k] && fieldMap[k]) {
        const type = fieldMap[k].type;
        let value = null;

        if (type !== "editgrid" && type !== "datagrid") {
          value = result[k] as SubmissionWithFields;
        } else {
          value = result[k].map((item: SubmissionWithFields) => {
            let result = {};
            Object.keys(item).forEach(key => {
              if (fieldMap[k].fields[key]) {
                result = {
                  ...result,
                  [fieldMap[k].fields[key].key]: item[key]
                };
              }
            });
            return result;
          });
        }
        accum.push({
          planEntityId: result.planEntityId,
          planId: result.planId,
          id: fieldMap[k].id,
          type,
          label: fieldMap[k].label,
          key: fieldMap[k].key,
          workspaceId: result.plan?.workspaceId,
          value,
          plan: result.plan
        } as FieldMapWithValue<T>);
      }
      return accum;
    }, [] as FieldMapWithValue<T>[]);

  if (results.length > 0) {
    return results.map(r => {
      return resultsMapped(r);
    });
  }
  return [];
};

export const getFormSubmissions = async <T>(options: {
  formId: number;
  workspaceIds?: number[];
  limit?: number;
  planYear?: number;
  where?: {
    "plan->planVersion.startDate": {
      $between: [string, string];
    };
  };
}): Promise<FieldMapWithValue<T>[][]> => {
  return fetch(
    `${BASE_URL}/plan/public/submission/digest/search`,
    postParams({ ...options, includeFieldMap: true })
  )
    .then(unwrap)
    .then((result: FormSubmissionResults<SubmissionWithFields>) => {
      return mapFormResults(result);
    });
};

export const getCrossPlansReport = async (
  blueprintId: number
): Promise<CrossPlanExport> => {
  const token = localStorage?.getItem("token") as string;
  return fetch(
    `${BASE_URL}/report/crossPlansExport/${blueprintId}`,
    postParams({}, { Authorization: `Bearer ${token}` })
  ).then(unwrap) as Promise<CrossPlanExport>;
};

export const getFileDocuments = async (options: {
  workspaceIds: number[] | undefined;
  regionIds?: number[] | undefined;
  fileDocumentTypeIds: number[] | undefined;
  years?: number[] | undefined;
  sort?: SortAPI[];
}): Promise<APIResults<Document>> => {
  let postParamsUpdated = postParams(options);
  const token = localStorage?.getItem("token") as string;
  if (token) {
    postParamsUpdated = postParams(options, {
      Authorization: `Bearer ${token}`
    });
  }
  return fetch(`${BASE_URL}/fileDocument/search`, postParamsUpdated).then(
    unwrap
  ) as Promise<APIResults<Document>>;
};

export const getFileDocumentTypes = async (): Promise<APIResults<
  DocumentType
>> => {
  return fetch(
    `${BASE_URL}/fileDocumentType/search`,
    postParams({ limit: 100 })
  ).then(unwrap) as Promise<APIResults<DocumentType>>;
};
