import { h } from "preact";
import { route } from "preact-router";
import MenuItem from "@mui/material/MenuItem";
import Menu, { MenuProps } from "@mui/material/Menu";
import CountryPicker from "components/common/CountryPicker";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";
import { styled, Theme } from "@mui/material";

interface Props {
  overridingClass?: string;
  links: { title: string; path: string }[];
  anchorEl: Element | null;
  handleClose: () => void;
  setCountryPicker?: boolean;
  sx?: any;
  [x: string]: any;
}

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& 	.MuiMenu-paper": {
    borderRadius: 0,
    borderTop: `2px solid ${theme.palette.primary.main}`
  }
}));

function SubMenu({
  links,
  anchorEl,
  handleClose,
  setCountryPicker = true,
  overridingClass,
  sx
}: Props): JSX.Element {
  const { t } = useTranslation();

  const onClose = (path: string) => {
    handleClose();
    route(path);
  };

  const renderMenuItemsLink = () => {
    return links.map(link => (
      <MenuItem onClick={handleClose} key={link.path}>
        <Link href={link.path} target="_blank" rel="noreferrer">
          <Typography
            variant="body1"
            sx={{
              marginRight: theme => theme.spacing(2),
              cursor: "pointer",
              color: theme => theme.palette.text.primary
            }}
          >
            {t(link.title)}
          </Typography>
        </Link>
      </MenuItem>
    ));
  };

  return (
    <StyledMenu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      className={overridingClass}
      elevation={1}
      keepMounted
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      disableAutoFocusItem={true}
      sx={sx ?? {}}
    >
      {renderMenuItemsLink()}
      {setCountryPicker && (
        <MenuItem onKeyDown={e => e.stopPropagation()}>
          <CountryPicker
            onCloseSubMenu={handleClose}
            variant="standard"
            redirect
            autoFocus
          />
        </MenuItem>
      )}
    </StyledMenu>
  );
}

export default SubMenu;
