export const CFR_DOMAIN_ID = 3;
export const IMS_DOMAIN_ID = 5;

export const UNDAF_PLANTYPE_ID = 7;
export const CFR_PLANTYPE_ID = 3;

export const OUTPUT_COREENTITYTYPE_ID = 8;
export const SUBOUTPUT_COREENTITYTYPE_ID = 9;
export const INDICATOR_COREENTITYTYPE_ID = 7;
export const STRATEGICPRIORITY_COREENTITYTYPE_ID = 10;
export const OUTCOME_COREENTITYTYPE_ID = 5;
export const JWT_COREENTITYTYPE_ID = 6;
export const SURVEY_COREENTITYTYPE_ID = 12;
