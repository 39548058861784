import { keyBy } from "lodash";
import { createContext } from "preact";
export const initialState = {
  year: undefined,
  years: [],
  workspaces: [],
  workspacesMap: undefined,
  user: undefined,
  allPlans: undefined,
  regions: [],
  documentTypes: []
};

export const GlobalContext = createContext<{
  state: {
    year?: string;
    years?: Timeframe[];
    workspaces?: Workspace[];
    workspacesMap?: { [key: number]: Workspace };
    user?: User | null;
    regions?: Region[];
    documentTypes?: DocumentType[];
  };
  dispatch: (action: Actions) => void;
}>({
  state: initialState,
  dispatch: () => null
});

type Actions =
  | { type: "setYear"; year: string }
  | { type: "setUser"; user: User | undefined | null } // A null user is one that wasn't returned, an undefined user is pre-log in attempt
  | { type: "setWorkspaces"; workspaces: Workspace[] }
  | { type: "setRegions"; regions: Region[] }
  | { type: "setDocumentTypes"; documentTypes: DocumentType[] }
  | { type: "setYears"; years: Timeframe[] };

export const reducer = (state: GlobalState, action: Actions): GlobalState => {
  switch (action.type) {
    case "setYear":
      return { ...state, year: action.year };
    case "setYears":
      return { ...state, years: action.years };
    case "setWorkspaces":
      return {
        ...state,
        workspaces: action.workspaces,
        workspacesMap: keyBy(action.workspaces, "id")
      };
    case "setRegions":
      return {
        ...state,
        regions: action.regions
      };
    case "setDocumentTypes":
      return {
        ...state,
        documentTypes: action.documentTypes
      };
    case "setUser":
      return { ...state, user: action.user };
    default:
      throw new Error();
  }
};
